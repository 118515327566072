#unknown {
  height: 100vh;
  text-align: center;
  font-size: 36px;
  letter-spacing: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#unknown a {
  letter-spacing: 2px;
  font-size: 16px;
}

#unknown span {
  margin-bottom: 20px;
}
